import "./App.css";
import IndexPage from "./components/IndexPage";

function App() {
  return (
    <>
      <IndexPage />{" "}
    </>
  );
}

export default App;
