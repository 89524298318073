import { toast } from "react-toastify";
import { unsubscribe } from "../apiEndPoints";
import React from "react";

export default function Home() {
  const websiteUrl = process.env.REACT_APP_API_MEDIAGE_HOST;
  const currentUrl = window.location.href;
  // new URLSearchParams(currentUrl);
  const token = new URLSearchParams(document.location.search).get("token");

  // const token = urlParams.get("token");

  const unsubscribed = async () => {
    try {
      // const dynamicParam = 3454;
      const response = await fetch(`${websiteUrl}/${unsubscribe}/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (!data.status) {
        toast.warn("Sorry, We're working on it");
        throw new Error("Network response was not ok");
      }

      if (data?.status) {
        console.log("Success");
        toast.success("Unsubscribed");
      }
    } catch (error) {
      console.error("Error during POST request:");
    }
  };
  return (
    <div
      className="bg-white"
      style={{ margin: "0 !important", padding: "0 !important" }}
    >
      <table
        border="0"
        cellpadding="0"
        cellspacing="0"
        style={{ margin: "0 auto" }}
      >
        <tr>
          <td
            align="center"
            bgcolor="#ebeff2"
            style={{ padding: "0px 10px 0 10px" }}
          >
            <table
              cellpadding="0"
              cellspacing="0"
              align="left"
              width="100%"
              style={{ maxWidth: "600px", marginTop: "8px" }}
            >
              <tr>
                <td>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    align="center"
                    width="100%"
                  >
                    <tr>
                      <td
                        bgcolor="#ffffff"
                        align="left"
                        valign="top"
                        style={{
                          width: "284px",
                          borderRadius: "16px 0px 0px 16px",
                          color: "#111827",
                          fontFamily: "sans-serif",
                          fontSize: "36px",
                          fontWeight: 600,
                          lineHeight: "38px",
                          padding: "35px 24px 30px 24px",
                        }}
                      >
                        <div>We&apos;re sorry to see you go!</div>
                        <p
                          style={{
                            margin: "8px 0px 0px 0px",
                            color: "#374151",
                            fontFamily: "sans-serif",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                          }}
                        >
                          Our newsletters were intended to provide knowledge,
                          not to fill your inbox...
                        </p>
                      </td>

                      <td
                        bgcolor="#ffffff"
                        align="center"
                        valign="top"
                        style={{
                          padding: "35px 24px 0px 24px",
                          borderRadius: "0px 16px 16px 0px",
                          color: "#111111",
                          fontFamily: "Lato, Helvetica, Arial, sans-serif",
                          fontSize: "48px",
                          fontWeight: 400,
                          lineHeight: "48px",
                        }}
                      >
                        <img
                          src="https://d3qziwubca5qnz.cloudfront.net/unsubscribe/banner-01.jpg"
                          width="163"
                          style={{ display: "block", border: "0px" }}
                          alt="Banner"
                        />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    align="left"
                    width="100%"
                    style={{ maxWidth: "600px", marginTop: "16px" }}
                  >
                    <tr>
                      <td
                        bgcolor="#ffffff"
                        align="left"
                        valign="top"
                        style={{
                          borderRadius: "16px 16px",
                          color: "#111827",
                          fontFamily: "sans-serif",
                          fontSize: "36px",
                          fontWeight: 600,
                          lineHeight: "40px",
                          padding: "35px 24px 32px 24px",
                        }}
                      >
                        <div>
                          <img
                            src="https://d3qziwubca5qnz.cloudfront.net/unsubscribe/icon-articles.png"
                            width="52"
                            style={{ display: "inline", border: "0px" }}
                            alt="Articles Icon"
                          />
                          <span
                            style={{
                              verticalAlign: "top",
                              lineHeight: "62px",
                              color: "#1F2937",
                            }}
                          >
                            13,000+
                          </span>
                          <span
                            style={{
                              fontSize: "24px",
                              lineHeight: "62px",
                              verticalAlign: "top",
                              color: "#1F2937",
                            }}
                          >
                            articles
                          </span>
                        </div>

                        <p
                          style={{
                            margin: "0px",
                            color: "#374151",
                            fontFamily: "sans-serif",
                            fontSize: "24px",
                            width: "410px",
                            fontWeight: 600,
                            lineHeight: "32px",
                          }}
                        >
                          from reputed medical journals from across the world
                        </p>

                        <p
                          style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#4B5563",
                            width: "476px",
                            fontWeight: "normal",
                            margin: "16px 0 0 0",
                          }}
                        >
                          Easy bite-sized format, with the core knowledge
                          intact, so you could consume it on-the-go.
                        </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    align="left"
                    width="100%"
                    style={{ maxWidth: "600px", marginTop: "16px" }}
                  >
                    <tr>
                      <td
                        bgcolor="#ffffff"
                        align="left"
                        valign="top"
                        style={{
                          borderRadius: "16px 16px",
                          color: "#111827",
                          fontFamily: "sans-serif",
                          fontSize: "36px",
                          fontWeight: 600,
                          lineHeight: "40px",
                          padding: "35px 24px 32px 24px",
                        }}
                      >
                        <div>
                          <img
                            src="https://d3qziwubca5qnz.cloudfront.net/unsubscribe/icon-cases.png"
                            width="52"
                            style={{ display: "inline", border: "0px" }}
                            alt="Cases Icon"
                          />
                          <span
                            style={{
                              verticalAlign: "top",
                              lineHeight: "62px",
                              color: "#1F2937",
                            }}
                          >
                            2400+{" "}
                          </span>
                          <span
                            style={{
                              fontSize: "24px",
                              lineHeight: "62px",
                              verticalAlign: "top",
                              color: "#1F2937",
                            }}
                          >
                            Cases
                          </span>
                        </div>

                        <p
                          style={{
                            margin: "0px",
                            color: "#374151",
                            fontFamily: "sans-serif",
                            fontSize: "24px",
                            width: "410px",
                            fontWeight: 600,
                            lineHeight: "32px",
                          }}
                        >
                          to keep you updated & ahead of the curve
                        </p>

                        <p
                          style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#4B5563",
                            width: "476px",
                            fontWeight: "normal",
                            margin: "16px 0 0 0",
                          }}
                        >
                          Share your challenging cases with industry experts to
                          start a discussion or engage in discussion on critical
                          cases, discussing their cause & potential diagnostics
                        </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    align="left"
                    width="100%"
                    style={{ maxWidth: "600px", marginTop: "16px" }}
                  >
                    <tr>
                      <td
                        bgcolor="#ffffff"
                        align="left"
                        valign="top"
                        style={{
                          borderRadius: "16px 16px",
                          color: "#111827",
                          fontFamily: "sans-serif",
                          fontSize: "36px",
                          fontWeight: 600,
                          lineHeight: "40px",
                          padding: "35px 24px 32px 24px",
                        }}
                      >
                        <div>
                          <img
                            src="https://d3qziwubca5qnz.cloudfront.net/unsubscribe/icon-videos.png"
                            width="52"
                            style={{ display: "inline", border: "0px" }}
                            alt="Videos Icon"
                          />
                          <span
                            style={{
                              verticalAlign: "top",
                              lineHeight: "62px",
                              color: "#1F2937",
                            }}
                          >
                            1400+{" "}
                          </span>
                          <span
                            style={{
                              fontSize: "24px",
                              lineHeight: "62px",
                              verticalAlign: "top",
                              color: "#1F2937",
                            }}
                          >
                            Videos
                          </span>
                        </div>

                        <p
                          style={{
                            margin: "0px",
                            color: "#374151",
                            fontFamily: "sans-serif",
                            fontSize: "24px",
                            width: "466px",
                            fontWeight: 600,
                            lineHeight: "32px",
                          }}
                        >
                          to watch, obtain knowledge, & get newer perspectives
                        </p>

                        <p
                          style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#4B5563",
                            width: "440px",
                            fontWeight: "normal",
                            margin: "16px 0 0 0",
                          }}
                        >
                          With every new video, we focus on bringing accurate &
                          genuine knowledge at your fingertips to consume.
                        </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <table
                cellpadding="0"
                cellspacing="0"
                align="center"
                width="100%"
                style={{ maxWidth: "600px", marginTop: "16px" }}
              >
                {token !== null && (
                  <tr>
                    <td
                      bgcolor="#ffffff"
                      align="center"
                      valign="top"
                      style={{
                        borderRadius: "16px 16px",
                        color: "#111827",
                        fontFamily: "sans-serif",
                        padding: "24px 24px 24px 24px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          lineHeight: "36px",
                          color: "#4B5563",
                          fontWeight: 500,
                          margin: "0px 0 0 0",
                        }}
                      >
                        So you have made your mind to leave us altogether?
                      </p>

                      <button
                        style={{
                          color: "#3167AF",
                          fontSize: "16px",
                          lineHeight: "16px",
                          textDecoration: "underline",
                          // borderBottom: "solid 2px #3167AF !important",
                          fontWeight: 600,
                          background: "none",
                          border: "none",
                          padding: "0px",
                          cursor: "pointer",
                          marginTop: "16px",
                        }}
                        onClick={unsubscribed}
                      >
                        Unsubscribe me
                      </button>

                      <br />
                      <br />
                    </td>
                  </tr>
                )}
              </table>
              {/* ... (Additional sections) ... */}

              {/* COPYRIGHT */}
              <tr>
                <td>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    align="center"
                    width="100%"
                    style={{ maxWidth: "600px", marginTop: "0px" }}
                  >
                    <tr>
                      <td
                        align="center"
                        valign="top"
                        style={{
                          borderRadius: "16px 16px",
                          color: "#111827",
                          fontFamily: "sans-serif",
                          padding: "34px 24px 24px 24px",
                        }}
                      >
                        <img
                          src="https://d3qziwubca5qnz.cloudfront.net/unsubscribe/logo.png"
                          width="140"
                          style={{ display: "inline", border: "0px" }}
                          alt="Logo"
                        />
                        <p
                          style={{
                            margin: "0",
                            color: "#6B7280",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "23px",
                          }}
                        >
                          Copyright © 2023 - MediSage. All Rights Reserved
                        </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      {/* CLEVERTAP SCRIPT */}
      <script>{/* Your Clevertap script goes here */}</script>
    </div>
  );
}
